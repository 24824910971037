/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      
     
$( ".animatedlink a" ).click(function(e) {
    $( ".project-link" ).addClass( "leaving" );
    setTimeout(function(){
         $( ".overlay" ).toggleClass( "transition" );
    }, 600); 
    e.preventDefault();  
    var goTo = this.getAttribute("href");
    setTimeout(function(){
         window.location = goTo;
    }, 900); 
});
  
$(window).load(function(){
    setTimeout(function(){
        $('.margo-loader').fadeOut();
    }, 100);
    setTimeout(function(){
        $( ".overlay-in" ).toggleClass( "transition-out" );
    }, 1200);
});  
      
          
$(window).load(function(){   
    //  Intro Animations
    setTimeout(function(){
        $('.header-animation').addClass('ready-animate');
    }, 2000);
    
    setTimeout(function(){
        $('.hero-img').addClass('scale');
    },4000);
    
    setTimeout(function(){
        $('.category-navigation').addClass('appear');
    },1000);
    
    setTimeout(function() { 
        $('.margolink').addClass('ready');
    }, 500); 
    
    var lines = document.querySelectorAll('.line-reveal');

     setTimeout(function() {
         $('#line-c').addClass('show');
    }, 4600);  
    
     setTimeout(function() {
         $('#line-w').addClass('show');
    }, 3800); 
   
     setTimeout(function() {
         $('#line-r').addClass('show');
    }, 4400); 
          
     setTimeout(function() {
         $('#line-c .lineoverlay').addClass('done');
         $('#line-w .lineoverlay').addClass('done');
         // $('#line-r .overlay').addClass('done');
    }, 6600);
          
     setTimeout(function() {
         $('#line-r .lineoverlay').addClass('done');
    }, 5600); 
   
});
          
// Get Width of Titles and append to parent div          
$(".project-link .margolink").each(function(){
    var title_width = $(this).find("span h2").width(); 
    $(this).css("width", title_width + 20);   
});
          
          
$(window).resize(function() {
$(".project-link .margolink").each(function(){
    var title_width = $(this).find("span h2").width(); 
    $(this).css("width", title_width + 20);   
});wrap  
});
          
/* A simple and scalable hamburger menu using css transitions. */
var isActive = false;

$('.js-menu').on('click', function() {
	if (isActive) {
		$(this).removeClass('active');
        $('.navbar').removeClass('open');
		$('body').removeClass('menu-open');
	} else {
		$(this).addClass('active');
        $('.navbar').addClass('open');
		$('body').addClass('menu-open');
	}
	isActive = !isActive;
});
    
$('.sortlink').on('click', function() {
    $('.category-nav').toggleClass('open');
});
          
          
          
if ( $(window).width() > 1024) {  
 
    $('.about-trigger').click(function(e){
        e.preventDefault();
        $('#myModal').modal('show');
    });
    
    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if (scroll >= 300) {

            $(".banner").addClass("scrolled");
        }
        else {
            $(".banner").removeClass("scrolled");
        } 
    });       
        
// do something only on ipad and below
    
} 
else {
  
    $('.about-trigger').on('click', function() {
        $('.js-menu').removeClass('active');
        $('.navbar').removeClass('open');
        $('body').removeClass('menu-open');
         setTimeout(function() {
             $('#myModal').modal('show');
        }, 600);  
    }); 
} 
    
if ($('.photo-link').hasClass('current-menu-item')) {
    $('.film-link').addClass('notincat');
    $('.film-link a').addClass('disabled');
    
    //$('.category-nav .nav').find('.film-link').children('a').removeAttr('href');
}   
          
if ($('.film-link').hasClass('current-menu-item')) {
    $('.photo-link').addClass('notincat');
    $('.photo-link a').addClass('disabled');
    //$('.category-nav .nav').find('.photo-link').children('a').removeAttr('href');
}          
        
// Load posts via AJAX
$(".post-link").click(function(event) {
    
            event.preventDefault();

            $("#loading-animation").show();
            var post_id = $(this).attr('rel');
            var ajaxURL = site.ajaxurl;

            // var cat_id = "film";
            // console.log(cat_id);

            $.ajax({
                type: 'POST',
                url: ajaxURL,
                data: {"action": "load-content", post_id: post_id },
                success: function(response) {
                    $("#project-container").html(response);
                    $("#loading-animation").hide();
                return false;
                },
                complete: function() {

        // Main/Product image slider for product page
        $('.main-img-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 800,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            asNavFor: '.thumb-nav',
            draggable: true,
            touchThreshold: 10, 
            // variableWidth: true,
            prevArrow: '<div class="slick-prev"></div>',
            nextArrow: '<div class="slick-next"></div>',
            infinite : false,
              responsive: [
                {
                  breakpoint: 1025,
                  settings: {
                    draggable: true 
                  }
                }
              ]
        });
        // Thumbnail/alternates slider for product page
        $('.thumb-nav').slick({
            slidesToShow: 8,
            slidesToScroll: 4,
            infinite: false,
            asNavFor: '.main-img-slider',
            dots: false,
            arrows: false,
            draggable: true,
            touchThreshold: 10,
            speed: 1200,
            variableWidth: true,
            focusOnSelect: true,
              responsive: [
                {
                  breakpoint: 1025,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    draggable: true
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                      focusOnSelect: false,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                  }
                }
              ]
        });

        //keeps thumbnails active when changing main image, via mouse/touch drag/swipe
        $('.main-img-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
          //remove all active class
          $('.thumb-nav .slick-slide').removeClass('slick-current');
          //set active class for current slide
          $('.thumb-nav .slick-slide:not(.slick-cloned)').eq(currentSlide).addClass('slick-current');  
        }); 

        $('.closeModal').click(function(){
            $('.modal').addClass("slideout");
            setTimeout(function(){
                $('.modal').modal("hide");
                $('.modal').removeClass("slideout");
            }, 600); 
            $("#project-container").html();
        });    
         
        }
    });
}); 
                 
var slickSlider = $('.main-vid-slider');
        
slickSlider.on('init', function(event, slick, currentSlide){ 
    
    $( ".video-slide-wrap:last-child" ).addClass('last-slide');
    $( ".video-slide-wrap:first-child" ).addClass('first-slide');
 

    if($('.slick-slide[data-video="379"]').hasClass('slick-current')) {
        $('.slick-prev').addClass('hidden');
        $('.slick-next').addClass('hidden');
    }    
    
    else if($('.slick-current').hasClass('first-slide')) {
       $('.slick-prev').addClass('hidden');
    }

    else {
        $('.slick-next').removeClass('hidden');
        $('.slick-prev').removeClass('hidden');
    }
});          
          
slickSlider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    fade:true,
    touchThreshold: 10,
    speed: 800,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>'
});
    
 $('.main-vid-slider').on('afterChange', function (event, slick, currentSlide) {
    $('.slick-next').removeClass('hidden');
    $('.slick-prev').removeClass('hidden');



    if($('.slick-current').hasClass('last-slide')) {
       $('.slick-next').addClass('hidden');
    }

    else if($('.slick-slide[data-video="379"]').hasClass('slick-current')) {
        $('.slick-next').addClass('hidden');
        $('.slick-prev').addClass('hidden');
    }

    else if(currentSlide === 0) {
        $('.slick-next').removeClass('hidden');
        $('.slick-prev').addClass('hidden'); 
    }          

    else {
        $('.slick-next').removeClass('hidden');
        $('.slick-prev').removeClass('hidden');
    } 

});
         
$('.videoTrigger').on('click', function() {
    // $('.main-img-slider').resize();
    $('#vidModal').modal('show');

    var artworkId = $(this).data('video');
    var artIndex = slickSlider.find('[data-video="' + artworkId + '"]').data('slick-index');
    var totalSlides = $('.slick-slide:not(.slick-cloned)').length;
    slickSlider.slick('slickPause').slick('slickGoTo', artIndex);
});
   

//bind our event here, it gets the current slide and pauses the video before each slide changes.
$(".main-vid-slider").on("beforeChange", function(event, slick) {
  var currentSlide, slideType, player, command;

  //find the current slide element and decide which player API we need to use.
  currentSlide = $(slick.$slider).find(".slick-current");

  //determine which type of slide this, via a class on the slide container. This reads the second class, you could change this to get a data attribute or something similar if you don't want to use classes.
  slideType = currentSlide.attr("class").split(" ")[1];

  //get the iframe inside this slide.
  player = currentSlide.find("iframe").get(0);

    command = {
      "method": "pause",
      "value": "true"
    };
  
  //check if the player exists.
  if (player != undefined) {
    //post our command to the iframe.
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  }
    
});

     
$('#vidModal').on('shown.bs.modal', function (e) {
    
    $('.main-vid-slider').resize();
    var iframe = $('.slick-current').find('#myiFrame');
    iframe.attr("src", iframe.data("src"));     
    
    // $('.main-vid-slider').resize();
});
         
$(".main-vid-slider").on("afterChange", function(event, slick, slickCurrentSlide) {
    var iframe = $('.slick-current').find('#myiFrame');
    iframe.attr("src", iframe.data("src")); 
});    
          
    
// Scroll Reveal
window.sr = ScrollReveal({
    //reset: true,
    opacity: 0,
    scale: 1,
    duration: 1000,
    distance: '100px',
    mobile: false,
    viewFactor: 0.4
});
sr.reveal('.fadein');

          
   

// Video Stuff
          
var timeoutId;
var $videoBgAspect = $(".videobg-aspect");
var $videoBgWidth = $(".videobg-width");
var videoAspect = $videoBgAspect.outerHeight() / $videoBgAspect.outerWidth();

function videobgEnlarge() {
  console.log('resize');
  windowAspect = ($(window).height() / $(window).width());
  if (windowAspect > videoAspect) {
    $videoBgWidth.width((windowAspect / videoAspect) * 100 + '%');
  } else {
    $videoBgWidth.width(100 + "%")
  }
}

$(window).resize(function() {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(videobgEnlarge, 100);
});

$(function() {
  videobgEnlarge();
});
          
          
  
var projectLinks = $('.project-link');      

projectLinks.waypoint(function(direction) {
  if (direction === 'down') {

        $(this).addClass('inview');    

  } else {
     // $(this).removeClass('inview')
  } 
}, { 
    offset: '80%'
});  
          

$('.closeModal').click(function(){
    $('.customModal').removeClass('active');
    $('.modal').addClass("slideout");
    
    // Remove Video Iframe
    $('.slick-current').find('#myiFrame').attr('src', '');
    // $('.slick-slide').find('.margovideo').attr('src', '');    
    
    
    setTimeout(function(){
        $('.modal').modal("hide");
        $('.modal').removeClass("slideout");
    }, 600);    
    

    
});
                
$('#galleryModal').on('shown.bs.modal', function (e) {
    $('.main-img-slider').resize();
});
          
          
if( $('.video-overlay').length){
			var options = {
				id: 76979871,
				width: 1200,
				loop: false
			};

			var player = new Vimeo.Player('video-container', options);

			// Open on play
			$('#video-trigger').click(function(){
				$('.video-overlay').addClass('show')
				player.play();
			})

			// Closes on click
			$('.video-toggle').click(function(){
  			$('.video-overlay').removeClass('show')
        player.pause();
  			setTimeout(function() {
				TweenMax.to('.site-head', 0.3, {autoAlpha: 1});
  				$('.video-overlay').css('left', '-100%')
				}, 300);
			})
}

          
$(document).ready(function () {
  var $container = $('.grid').imagesLoaded( function() {
    // initialize Packery after all images have loaded
    $container.packery({
        itemSelector: '.item',
        percentPosition: true
    });
  });
});   
          
   
          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


          
        $(window).scroll(function() { 
            var scroll = $(window).scrollTop();
            if (scroll >= 300) {
                $(".banner").removeClass("scrolled");
            }
            else {
                $(".banner").removeClass("scrolled");
            } 
        });
          
          
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
      
    // Category page
    'category': {
      init: function() {
        // JavaScript to be fired on the Category page  
            $('.menu-item-29').addClass('current_page_item');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
      
    'category_film': {
      init: function() {
        // JavaScript to be fired on the Category page  
          
          
     $('.main-vid-slider').on('afterChange', function (event, slick, currentSlide) {
         
        if($('.slick-slide[data-video="75"]').hasClass('slick-current')) {
            $('.slick-prev').addClass('hidden');
        }           
         
        else {
            $('.slick-next').removeClass('hidden');
            $('.slick-prev').removeClass('hidden');
        } 

    });
          
          
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

      

      
    // Category page
    'post_type_archive_play_projects': {
      init: function() {
        // JavaScript to be fired on the Category page  
        $('.menu-item-28').addClass('current_page_item');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Thats A Wrap page
    'thats_a_wrap': {
      init: function() {
        // JavaScript to be fired on the Category page  
        $('.wrap-link').addClass('current-menu-item');
              

          
$(function(){
  var accessToken = '835073895.9527d16.9ca358b024b84a9ea79f3403cdef2905';
  $.getJSON('https://api.instagram.com/v1/users/self/media/recent/?access_token='+accessToken+'&callback=?',function (insta) {
    $.each(insta.data,function (photos,src) {
      if ( photos === 20 ) { 
          return false; 
      }
      $('<div class="item col-xs-12 col-sm-12 col-md-6"><div class="project-link fadein">'+
        '<a href="'+src.link+'" target="_blank">'+
        '<figure class="project-img"><img src="'+src.images.standard_resolution.url+'" class="img-responsive" /></figure><div class="desc"><h4>Instagram</h4><p>Show me</p></div>'+
        '</a>'+
      '</div></div>').appendTo('#ig-sec');   
    });
      
    $('.item:nth-child(2)').after('<div class="item col-xs-12 col-sm-12 col-md-6"><div class="insta-desc"><h5><span class="ig-icon"></span>Follow this project <a href="https://www.instagram.com/margo_weathers/" target="_blank">@margo_weathers</a></h5></div></div>');  
    $('.item:nth-child(4)').after('<div class="item col-xs-12 col-sm-12 col-md-6"><div class="insta-desc"><p>Some feel the arrival of the gift bag was the death of an art form.  In a world where almost any gift can come from a click, bespoke and thoughtful gift wrapping can be the pivotal curtain rise to the main event of a gift, and a great way of gifting respect in the process.  That’s a Wrap is a year-long installation on Instagram: one of 365 new images of an alternatively-wrapped gift posted every day.</p></div></div>');
    
    // $('.item').addClass('col-xs-12 col-sm-12 col-md-6');
    var smallerposts = $('.item:nth-child(7), .item:nth-child(8), .item:nth-child(11), .item:nth-child(12), .item:nth-child(20), .item:nth-child(21)');
      
    smallerposts.removeClass('col-xs-12 col-sm-12 col-md-6');
    smallerposts.addClass('col-xs-6 col-sm-6 col-md-3');


    sr.reveal('.fadein');


  var $container = $('#ig-sec').imagesLoaded( function() {
    // initialize Packery after all images have loaded
    $container.packery({
        itemSelector: '.item',
        percentPosition: true
    });
  });

      
      
  });
    
    
}); 
          

          
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Books page
    'books': {
      init: function() {
        // JavaScript to be fired on the Category page  
        $('.books-link').addClass('current-menu-item');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Archive page
    'archive': {
      init: function() {
        // JavaScript to be fired on the Archive page
        

      
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
